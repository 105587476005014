var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bac6729fd32150899494d847b43832bad627b59f"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { excludeGraphQLFetch } from 'apollo-link-sentry';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  beforeBreadcrumb: excludeGraphQLFetch,
  beforeSend: (event) => {
    if (
      event.exception &&
      event.exception.values &&
      event.exception.values.length
    ) {
      const lastException =
        event.exception.values[event.exception.values.length - 1];

      if (lastException) {
        // Ignore events coming from Google Tag Manager.
        if (
          lastException.stacktrace?.frames?.some((f) =>
            f.module?.includes('gtag')
          )
        ) {
          return null;
        }

        // Ignore events coming from LogRocket.
        if (
          lastException.stacktrace?.frames?.some((f) =>
            f.module?.includes('logger')
          )
        ) {
          return null;
        }

        if (
          event.exception?.values?.[0].value.includes(
            'Invariant: attempted to hard navigate to the same URL'
          )
        ) {
          return null;
        }
      }
    }

    return event;
  },
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Logrocket
    /r\.lr-in\.com/i,
    // Segment
    /segment\.io/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  dsn:
    SENTRY_DSN ||
    'https://bb1251e701924182b2c29fea86b3d05b@o129864.ingest.sentry.io/6251767',
  enabled: process.env.NODE_ENV === 'production',
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',
  ],
  tracesSampleRate: 0.25,
});
